import Vue from 'vue';
import Router from 'vue-router';
//components

import Full from '@/containers/Full';
import Login from '@/components/Login';

import LawMain from '@/components/LawMain';

import AfterAuth from '@/components/AfterAuth';
import Organization from '@/components/law/Organization'
import OrganizationUsers from '@/components/law/OrganizationUsers'
import UserAccount from '@/components/law/UserAccount'
import LegalAccount from '@/components/law/LegalAccount'
import Charging from '@/components/law/Charging'
import CarList from '@/components/law/CarList'
import CarInfo from '@/components/law/CarInfo'
import CompUser from '@/components/law/CompUser'
import Check from '@/components/law/Check'

//begining of the landing page of system


Vue.use(Router);
export default new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path:'/check/:documentId',
            name:'Check',
            component:Check,
        },
        {
            path: '/main',
            name: 'Main',
            component: LawMain,
        },
        {
            path: '/afterauth',
            name: 'AfterAuth',
            component: AfterAuth,
        },
        {
            path: '/',
            redirect: '/main',

            component: Full,
            children: [
                {
                    path: '/organizations',
                    name: 'Organization',
                    component: Organization,
                    meta:{requiresAuth:true}
                },
                {
                    path: '/organizationusers',
                    name: 'OrganizationUsers',
                    component: OrganizationUsers,
                    meta:{requiresAuth:true}
                },
                {
                    path: '/users',
                    name: 'ComUser',
                    component: CompUser,
                    meta:{requiresAuth:true}
                },
                {
                    path:'/useraccount',
                    name:'UserAccount',
                    component:UserAccount,
                    meta:{requiresAuth:true}
                },
                {
                    path:'/accounts',
                    name:'LegalAccount',
                    component:LegalAccount,
                    meta:{requiresAuth:true}
                },
                {
                    path:'/charging/:account',
                    name:'Charging',
                    component:Charging,
                    meta:{requiresAuth:true}
                },

                {
                    path:'/carlist/:accountNumber',
                    name:'CarList',
                    component:CarList,
                    meta:{requiresAuth:true}
                },
                {
                    path:'/carinfo/:plate',
                    name:'CarInfo',
                    component:CarInfo,
                    meta:{requiresAuth:true}
                }
            ]
        }
    ]
})