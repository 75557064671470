<template>
    <b-row>
         

         <b-col lg="8">
            <b-form-input
                class="w-100 form-control-sm"
                v-model="filter"
                type="search"
                id="filterInput"
                :placeholder="$i18n.locale()=='kz' ? 'Іздеу' : 'Поиск'"
                ></b-form-input>
        </b-col>
        
        

        <b-col lg="4" class="pt-3 text-right">
            <strong>
            {{$i18n.locale()=='kz' ? 'Жалпы саны:' : 'Общее число'}}
            </strong> {{totalRows}}  
        </b-col>
        <b-col lg="12">
              <b-table 
                ref="orgRef"
                striped hover selectable
                :items="recordProvider" 
                :fields="fields"
                :busy.sync="isBusy"
                :current-page="currentPage"
                :per-page="perPage"
                table-variant='light'
                :filter="filter"
                :select-mode="selectMode"
                @row-dblclicked="addDep"
                @row-selected="onRecordSelected"
                >
                <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Жүктелуде...</strong>
                    </div>
                </template>
               
                <template #head(fio)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(idn)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(role)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(createdAt)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(name)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>

                <template v-slot:cell(name)="row">
                    <b-alert show  variant="danger" v-html="row.item.name+'<br>'+'<strong>'+row.item.bin+'</strong>'"></b-alert>
                </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
               
                size="sm"
                class="my-0"
            ></b-pagination>

            
            
            <DepModal :orgId="orgId" :bin="bin" :orgName="orgName"></DepModal>
            
        </b-col>
    </b-row>
</template>
<script>
import axios from 'axios';
import {apiDomain,getHeader,downloadHeader} from "@/config/config";
import CatForm from "@/components/oyu/CatForm"
import DepModal from "@/components/law/DepModal"
import vue from 'vue';
import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext"
export default {
    name:"OrganizationUsers",
    components:{CatForm,DepModal},
    data(){
        return {
            orgId:0,
            bin:"",
            orgName:"",

            fields: [
                {
                    key: 'fio',
                    label: 'qaj.users.fio',
                    thStyle: { width: "20%" } ,
                    variant:"primary"
                },
                {
                    key: 'idn',
                    label: 'qaj.users.idn',
                  
                },
                {
                    key: 'role',
                    label: 'qaj.users.role',
                },
                {
                    key:"createdAt",
                    label:"qaj.users.createdAt",
                },
                {
                    key:"name",
                    label: 'qaj.users.name',   
                }
               
            ],
            isBusy:false,
            totalRows:0,
            currentPage: 1,
            perPage: 20,
            tableVariant:'light',
            filter:"",
            selectMode:'single',
            selectedRows:[],

            catId:0,
            status:"",
            orgType:"",

            
        }
    },
    methods:{
        addDep(row){
            // bul jerde row.item.organizationId bul userId negizi prosta paidaldik
            let dbOrg = "";

            this.orgId = "";
            this.bin = "";
            this.orgName = "";
            axios.post(apiDomain+'/law/userrootorg',{"userId":row.organizationId},{headers:getHeader()})
            .then(response=>{
                let dbOrg = response.data;

                this.orgId = dbOrg.organizationId;
                this.bin = dbOrg.bin;
                this.orgName = dbOrg.name;
                
                this.$bvModal.show("depModal");
            })
            .catch(error => {
                alert(error.message);
            })
            
           
        },
       
        sysChange(){
            this.$refs.orgRef.refresh();  
        },   
        onRecordSelected(items){
            this.selectedRows=items;
        },
        
        recordProvider(ctx){
            ctx.status = this.status;
            ctx.orgType=this.orgType;
            this.isBusy = false
            let promise = axios.post(apiDomain+'/law/organizationusers',ctx,{headers:getHeader()});
                return promise.then((response) => {
                const result = response.data;
                this.isBusy = false
                this.totalRows=result.recordCount;
                //alert(JSON.stringify(result));
                return(result.items)
            }).catch(error => {
                this.isBusy = false
                return []
            })
        }
    },
    created(){
    
    }   
}
</script>
