<template>
    <b-modal id="oyuCatModal" :title="$t('oyu.oyuCat.createOyuCat')" hide-footer size="lg" @hidden="clearForm();sysChange()">
        <b-form v-on:submit.prevent="createCat">
            <b-form-row class="mb-3">
                <b-col sm="auto" lg="12">
                    <label for="isActive">{{ $t('comboChooseText') }}</label>
                    <select required id="isActive" class="form-control" v-model="catForm.isActive">
                        <option :value=1>{{ $t('lms.courseList.yes') }}</option>
                        <option :value=0>{{ $t('lms.courseList.no') }}</option>
                    </select>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="catName">{{$t('oyu.oyuCat.catName')}}</label>
                    <b-form-input required id="catName" class="form-control" v-model="catForm.catName"/>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="catNameRu">{{$t('oyu.oyuCat.catNameRu')}}</label>
                    <b-form-input required id="catNameRu" class="form-control" v-model="catForm.catNameRu"/>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="catNameEn">{{$t('oyu.oyuCat.catNameEn')}}</label>
                    <b-form-input required id="catNameEn" class="form-control" v-model="catForm.catNameEn"/>
                </b-col>

                <b-col sm="auto" class="pt-3" lg="12">
                    <label for="catIcon">{{$t('oyu.oyuCat.catIcon')}}</label>
                    <b-form-textarea class="form-control" style="height:100px;"
                    id="catIcon" required v-model="catForm.catIcon" ></b-form-textarea>
                </b-col>
                

                
                <b-row class="pt-2 text-right">
                    <b-col lg="12" class="text-right">
                        <b-button size="sm" variant="primary" class="mr-2" type="submit">{{$t("lms.add")}}</b-button>
                        <b-button size="sm" variant="danger" @click="clearForm">{{$t("lms.clear")}}</b-button>
                    </b-col>
                </b-row>
            </b-form-row>
        </b-form>
    </b-modal>
</template>
<script>
import axios from 'axios';
import {apiDomain,getHeader} from "@/config/config";
import vue from 'vue';
export default{
    name:"OyuCatForm",
    props:["sysChange"],
    data(){
        return {
            catForm:{
                catName :"",
                catNameRu :"",
                catNameEn:"",
                catIcon:"",
                isActive:1
            }   
        }
    },
    methods: {
        
        updateCategory(catId){
            axios.post(apiDomain+'/oyu/cat/current',{catId:catId},{headers:getHeader()})
            .then(response=>{
                this.catForm=response.data;
                this.$bvModal.show('oyuCatModal')    
            })
            .catch(error => {
                //console.log(error.message)
                this.$bvToast.toast(error.message, {
                    title: 'алдаа',
                    autoHideDelay: 5000
                })
            }) 
        },
        createCat(){
           

            axios.post(apiDomain+'/oyu/cat/create',this.catForm,{headers:getHeader()})
            .then(response=>{
                if(response.data=='success'){
                    this.clearForm();
                    this.sysChange();
                    this.$bvToast.toast(vue.i18n.translate("system.successMsg"), {
                        variant:"success",
                        title: vue.i18n.translate("system.successTitle"),
                        autoHideDelay: 5000
                    })
                }
            })
            .catch(error => {
                alert(error.message);
            })        
            
        },
        clearForm(){
            this.catForm={
                catName :"",
                catNameRu :"",
                catNameEn:"",
                catIcon:"",
                isActive:1
            }
        }
    }    
}
</script>