<template>
  <b-modal v-model="localIsVisible" title="Детали оплаты" @hide="onHide" size="lg">
    <div v-if="paymentDetails">
      <!-- Основная информация -->
      <p><strong>Полоса оплаты:</strong> {{ paymentDetails.descriptionRu }}</p>
      <p><strong>Сумма:</strong> {{ paymentDetails.cash }}</p>
      <p><strong>Дата оплаты:</strong> {{ paymentDetails.createdDate }}</p>

      <!-- Таблица с массивом items -->
      <div v-if="paymentDetails.items && paymentDetails.items.length">
        <b-table striped hover :items="Array.isArray(paymentDetails.items) ? paymentDetails.items : []" :fields="fields">
          <template #cell(amount)="data">
            <span>{{ data.item.amount }}</span>
          </template>
          <template #cell(passageDate)="data">
            <span>{{ formatDate(data.item.passageDate) }}</span>
          </template>
          <template #cell(roadSectorName)="data">
            <span>{{ data.item.roadSectorName }}</span>
          </template>
        </b-table>
      </div>
      <div v-else>
        <p>Нет деталей для отображения.</p>
      </div>
    </div>
    <div v-else>
      <p>Нет доступных данных.</p>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    paymentDetails: {
      type: Object,
      required: false,
      default: null
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localIsVisible: this.isVisible,
      fields: [
        {key: 'amount', label: 'Сумма'},
        {key: 'passageDate', label: 'Дата проезда'},
        {key: 'roadSectorName', label: 'Название участка дороги'}
      ],
    };
  },
  methods: {
    formatDate(isoDate) {
      if(!isoDate) return '';
      const date = new Date(isoDate);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяцы идут от 0 до 11
      const year = date.getUTCFullYear();

      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    },
    onHide() {
      this.$emit('update:isVisible', false);
    }
  },
  watch: {
    isVisible(newValue) {
      this.localIsVisible = newValue;
    }
  }
};
</script>
