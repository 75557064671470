<template>
  <div class="footer">
    <div class="footer-info">
      <h1 class="footer-title">
        {{$t("qaj.org.secfooterTitle")}}
      </h1>
      <p class="footer-text">
        {{$t("qaj.org.secfootertext1")}}
      </p>
      <p class="footer-text2">
        {{$t("qaj.org.secfootertext2")}}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SecFooter"
  }
</script>
<style scoped>
.footer {
  padding: 10px;
  background-color: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  text-align: center;
  //margin-top: 10px;
  width: 70%; /* Set your desired width */
  margin: auto;
}

.footer-info {
  color: #333;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-text {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1px;
}
.footer-text2 {
  font-size: 14px;
  word-spacing: 0px;
  margin-top: 1px;
}
</style>
