<template>
    <b-modal id="orgModal" 
        :title="$t('qaj.reg.regTitle')" 
        hide-footer size="md" 
        @hidden="clearForm();sysChange()">
        <b-form v-on:submit.prevent="createOrg">
            <b-form-row class="mb-3">
                <b-col sm="auto" lg="12">
                    <label for="orgType">{{ $t('qaj.reg.type') }}</label>
                    <select required id="orgType" class="form-control" 
                        v-model="eloginData.orgForm.orgType">
                        <option value="">
                            {{ $i18n.locale()=='kz' ? 'Таңдаңыз...' : 'Выберите...' }}
                        </option>
                        <option v-for="(o,oi) in orgTypes" :key="oi" :value="o.code"> 
                            {{$i18n.locale()=='kz' ?  o.nameKz : o.nameRu }}
                        </option>
                    </select>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="director">{{$t('qaj.reg.director')}}</label>
                    <b-form-input required id="director" class="form-control" v-model="eloginData.orgForm.director"/>
                </b-col>
                <b-col sm="auto" lg="12" v-if="eloginData.orgForm.orgType!='ИП'">
                    <label for="bin">{{$t('qaj.reg.bin')}}</label>
                    <b-form-input required id="bin" class="form-control" v-model="eloginData.orgForm.bin"/>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="email">{{$t('qaj.reg.email')}}</label>
                    <b-form-input type="email" 
                        required id="email" class="form-control" v-model="eloginData.orgForm.email"/>
                </b-col>
                <b-col lg="12">
                    <b-row class="pt-2">
                        <b-col lg="6">
                            <b-button size="sm" block variant="primary" @click="clearForm" class="mr-2" >{{$t("qaj.reg.back")}}</b-button>
                            
                        </b-col>
                        <b-col lg="6">
                            <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.reg.reg")}}</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-form-row>
        </b-form>
    </b-modal>
</template>
<script>
import axios from 'axios';
import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext";
import {getHeader,apiDomain} from "@/config/config";
import vue from 'vue';
const authUser = {};
export default{
    name:"RegModal",
    props:["sysChange"],
    data(){
        return {
            eloginData :{
                connectionId: "",
                xmlSignature: "",
                password: "",

                orgForm:{
                    orgType:"",
                    director:"",
                    bin:"",
                    email:""
                },
                loginType:0
            },
            orgTypes:[
                {
                    code:"ИП",
                    nameKz:"Жеке кәсіпкер",
                    nameRu:"Индивидуальный предприниматель"
                },
                {
                    code:"ТОО",
                    nameKz:"Жауапкершілігі шектеулі серіктестік",
                    nameRu:"Товарищество с ограниченной ответственностью"
                },
                {
                    code:"АО",
                    nameKz:"Акционерлік қоғам",
                    nameRu:"Акционерное общество"
                },
                {
                    code:"КГУ",
                    nameKz:"Коммуналдық мемлекеттік мекемелер",
                    nameRu:"Коммунальные государственные учреждения"
                },
                {
                    code:"ГУ",
                    nameKz:"Мемлекеттік мекеме",
                    nameRu:"Государственное учреждение"
                },
                {
                    code:"ГКП на ПХВ",
                    nameKz:"Шаруашылық жүргізу құқығындағы мемлекеттік коммуналдық кәсіпорын",
                    nameRu:"Государственное коммунальное предприятие на праве хозяйственного ведения"
                },
                
                {
                    code:"НАО",
                    nameKz:"Коммерциялық емес акционерлік қоғам",
                    nameRu:"Некоммерческого акционерного общества"
                },
                {
                    code:"РГП",
                    nameKz:"Республикалық мемлекеттік кәсіпорын",
                    nameRu:"Республиканское государственное предприятие"
                },
                {
                    code:"РГП на ПХВ",
                    nameKz:"Шаруашылық жүргізу құқығындағы республикалық мемлекеттік кәсіпорын",
                    nameRu:"Республиканского государственного предприятия на праве хозяйственного ведения"
                },
                {
                    code:"РГКП",
                    nameKz:"Республикалық мемлекеттік қазыналық кәсіпорын",
                    nameRu:"Республиканское государственное казённое предприятие"
                },
            ]   
        }
    },
    methods: {
       
        etspToken() {
            axios.post(apiDomain + "/etsptoken", {}, {headers: getHeader()})
            .then(res => {
                this.xmlSignature(res.data);
            })
            .catch(error => {
                alert(error.message)
            });
        },
        async xmlSignature(res) {
            let NCALaClient = new NCALayerClientExtension();
            console.log("nc object ", NCALaClient);
            try {
                await NCALaClient.connect();
            } catch (error) {
                alert(error.message);
                return
            }
            try {
                let XMLignature = await NCALaClient.sign('xml', null, res.xml, 'auth', this.$i18n.locale, false)
                // let XMLignature = await  NCALaClient.signXml('PKCS12', res.xml, 'AUTH')
                this.eloginData.xmlSignature = XMLignature[0];
                this.eloginData.connectionId = res.connectionId;

                this.sendLoginData();
            } catch (error) {
                alert(error.message);
            }
        },
        
        sendLoginData() {
            if (this.eloginData.connectionId.length > 4 && this.eloginData.xmlSignature.length > 10) {
                axios.post(apiDomain + "/etspverify", this.eloginData, {headers: getHeader()})
                .then(res => {
                    if (res.status === 200) {
                        authUser.access_token = res.data.access_token;
                        authUser.refresh_token = res.data.refresh_token;
                        window.localStorage.setItem('authUser', JSON.stringify(authUser));
                        this.$router.push({name: 'AfterAuth'});
                    }
                    
                })
                .catch(error => {
                    if(error.response && error.response.status){
                        let st = error.response.status;
                        if(st==999){
                            alert("already registered");
                        }else if (st==745){
                            alert(vue.i18n.translate('qaj.reg.idnNotSame'));
                        }else if(st===409){
                            alert(vue.i18n.translate('qaj.reg.adminError')); // admin hay companyaga map jasaytinin sheshedi
                        }
                        else if(st===410){
                            alert("EMAIL : "+ vue.i18n.translate('qaj.reg.mailError')); 
                        }
                        else{
                            alert(vue.i18n.translate('qaj.reg.mailError'));
                        }
                    }else{
                        alert(vue.i18n.translate('qaj.reg.unknownError'));
                    }
                    
                });
            }
        },
       
        createOrg(){
            this.eloginData.connectionId="";
            this.eloginData.xmlSignature="";
            this.eloginData.password="";

            this.etspToken();

        },
        clearForm(){
            
            this.eloginData = {
                connectionId: "",
                xmlSignature: "",
                password: "",
                orgForm:{
                    orgType:"",
                    director:"",
                    bin:"",
                    email:""
                }
            };
            this.$bvModal.hide("orgModal");
        }
    }    
}
</script>