<template>
    <div class="app">
        <AppHeader/>
        <b-container class="pt-5 mt-5" fluid>
            <h4  v-if="hasRole('USER')"  class="text-center mb-4"><span v-html="rootOrganizaion.name"></span> ({{rootOrganizaion.bin}})</h4>
            <hr v-if="hasRole('USER')">
            <router-view></router-view>
        </b-container>
        <div class="w-100 py-5 text-center" >
          <a href="javascript:void(0)">law.kaztoll.kz</a> 2023
        </div>
   </div>
</template>

<script>
import AppHeader from '../components/AppHeader';
import axios from 'axios';
import {mapActions,mapState} from 'vuex';
import {apiDomain,getHeader} from "@/config/config";
export default {
  name: 'Full',
  components: {
    AppHeader
  },
  data(){
    return {
      rootOrganizaion:{}
    }
    
  },
  methods:{
    rootOrg(){
        //alert("me is called");
        axios.post(apiDomain+'/law/rootorg',{},{headers:getHeader()})
        .then(response=>{
            this.rootOrganizaion=response.data;    
        })
        .catch(error => {
            //alert(error.message);
        })
    },
    hasRole(roleName){
      if(this.loginedUser.userRoles){
        let roleNames = this.loginedUser.userRoles;
        if(roleNames.length>0){
          //alert(JSON.stringify(roleNames));
          for(let r=0;r<roleNames.length;r++){
            
            if(roleNames[r].name===roleName){
              return true;
            }
          }
        }
        else{
          return false;
        }
      }
      return false;
      
      
    }
  },
  created(){
    if(this.hasRole('USER')){
      this.rootOrg();
    }
    
  },

  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
    },
    ...mapState([
        'loginedUser'
    ]),
  }
}
</script>
