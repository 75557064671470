<template>

    <b-row>
        <b-col cols="12">
            <div class="w-100 text-right pb-2">
                <b-btn size="sm"  v-b-modal.carModal>
                    {{ $t("qaj.legal.addCar")}}
                </b-btn>
            </div>
            <b-table 
                ref="carRef"
                striped hover selectable
                :items="recordProvider" 
                :fields="fields"
                :busy.sync="isBusy"
                :current-page="currentPage"
                :per-page="perPage"
                table-variant='light'
                :filter="filter"
                :select-mode="selectMode"
                selected-variant="primary"
                @row-selected="onRecordSelected">
               
                <template v-slot:table-busy>
                <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Жүктелуде...</strong>
                </div>
                </template>

                <template #head(selected)="data">
                    <span aria-hidden="true">&check;</span>
                </template>

                <template #head(licencePlate)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(classCode)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(totalAmount)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(actions)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template v-slot:cell(actions)="row">
                
                    <b-btn variant="success" 
                    class="ml-1" size="sm" @click="carInfo(row.item.licencePlate) ">{{ $t("qaj.legal.more").toUpperCase() }}</b-btn>

                    <b-btn variant="danger" 
                    class="ml-1" size="sm" @click="removeVehicle(row.item.licencePlate)" >{{ $t("qaj.legal.delCar").toUpperCase() }}</b-btn>
                </template>
            </b-table>


           
        </b-col>
        <b-col class="text-center" cols="12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
               
                size="sm"
                class="my-0"
            ></b-pagination>
        </b-col>
        <b-modal id="carModal" 
            :title="$t('qaj.legal.addCar')" 
            hide-footer size="md" 
            @hidden="clearForm();sysChange()">
            <b-form v-on:submit.prevent="addVehicle">
                <b-form-row class="mb-3">
                    <b-col lg="12" class="text-right pb-3" >
                        <b-button size="sm" @click="addCar()">{{$t("qaj.legal.plus")}}[+]</b-button>
                    </b-col>

                    <b-col lg="12">
                        <b-row v-for="(c,ci) in cars" :key="ci" class="mb-2">
                            <b-col sm="auto" lg="8">
                                <input type="text" @keydown="checkKeyDownAlphaNumeric" v-model="cars[ci].licencePlate" 
                                placeholder="ГРНЗ" required id="director" class="form-control"/>
                            </b-col>

                            <b-col lg="4">
                                <b-button size="sm" class="mt-1" :disabled="ci==0 ? true : false" block variant="danger" @click="rmCar(ci)">
                                    {{$t("qaj.legal.minus")}}[-]
                                </b-button>
                            </b-col> 
                        </b-row>
                    </b-col>
                    <b-col lg="12">
                        <b-row class="pt-2">
                            <b-col lg="6">
                                <b-button size="sm" block variant="primary" @click="clearForm" class="mr-2" >{{$t("qaj.reg.back")}}</b-button>
                                
                            </b-col>
                            <b-col lg="6">
                                <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.reg.reg")}}</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-form-row>
            </b-form>
        </b-modal>
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import DocModal from "@/components/law/DocModal"
    import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext"
    import vue from 'vue';
    export default {
        name : "LegalAccount",
        data(){
            return {
                cars:[{"accountNumber":"","licencePlate":""}],
                accounts:[],
                //"balance": 0, "createdDate": "2023-10-23T10:07:22.402Z", "modifiedDate": "2023-10-23T10:07:22.402Z", "contractNumber": "308/23 от 23.10.2023г."
                fields: [
               
           
                    {
                        key: 'licencePlate',
                        label: 'qaj.legal.licencePlate',
                        thStyle: { width: "20%" } ,
                        
                    },
                    {
                        key: 'classCode',
                        label: 'qaj.legal.class',
                    
                    },
                    {
                        key: 'totalAmount',
                        label: 'qaj.legal.totalAmount',
                    },
                    {
                        thStyle: { width: "30%" } ,
                        key:"actions",
                        label:"qaj.org.actions",
                        variant:'secondary'
                    },
                ],
                isBusy:false,
                totalRows:0,
                currentPage: 1,
                perPage: 20,
                tableVariant:'light',
                filter:"",
                selectMode:'single',
                selectedRows:[],
                accountNumber:"",

                dataBase64:""
            }
        },
        methods:{
            generateBase64StringWithTimestamp() {
                // Generate a timestamp
                const timestamp = Date.now().toString();

                // Encode the timestamp string directly using Base64
                const base64Timestamp = Buffer.from(timestamp).toString('base64');

                // Output the Base64 string
                console.log("Base64 string with timestamp:", base64Timestamp);
                this.dataBase64 = base64Timestamp;
                return base64Timestamp;
            },
            rmCar(ci){
                this.cars.splice(ci,1)
            },
            sysChange(){
                this.$refs.carRef.refresh();
            },
            clearForm(){
                this.cars=[];
                this.cars.push({"accountNumber":"","licencePlate":""});
                this.$bvModal.hide("carModal")
            },
            addCar(){
                this.cars.push({"accountNumber":"","licencePlate":""});
            },
            hasDuplicates(array) {
                const set = new Set();

                for (const item of array) {
                    const key= `${item.licencePlate}-${item.accountNumber}`;
                    if (set.has(key)) {
                        return true;
                    }
                    set.add(key);
                }
                return false;
            },
            async signForAction() {
                let NCALaClient = new NCALayerClientExtension();
                try {
                    await NCALaClient.connect();
                } catch (error) {
                    alert(error);
                    return 
                }
                try {
                    if(this.dataBase64.length==0)
                        this.generateBase64StringWithTimestamp();
                    let signature = await NCALaClient.sign('cms', {}, this.dataBase64, null, this.$i18n.locale, true);
                    if (Array.isArray(signature)) {
                        signature = signature[0];
                    }
                    return signature;
                } catch (error) {
                    alert(error)
                    
                }
            },

            async addVehicle(){
                if(this.accountNumber.length>0 && this.cars.length>0){
                    if(this.hasDuplicates(this.cars)){
                        alert(vue.i18n.translate("qaj.legal.carDub"));
                        return ;
                    }
                    const pCars = this.cars.map(car=>({...car,accountNumber:this.accountNumber}));

                    let sig = await this.signForAction();
                    if(sig!=null && sig.length>10){
                        
                        axios.post(apiDomain+'/law/signaction',
                        {"dataBase64":this.dataBase64,"sig":sig,"body":sig},{headers:getHeader()})
                        .then((res)=>{
                            if(res.data=='success'){
                                axios.post(apiDomain+'/law/addvehicle',{"cars":pCars},{headers:getHeader()})
                                .then(response=>{
                                    if(response.data=='success'){
                                        
                                        this.clearForm();    
                                        this.sysChange();
                                        this.$bvModal.hide("carModal");
                                    }
                                })
                                .catch(error => {
                                    alert(error.message);
                                })   
                            }else{
                                this.$bvToast.toast(vue.i18n.translate("qaj.reg.carSignError"), {
                                    title: 'ERROR',
                                    variant:"danger",
                                    autoHideDelay: 5000
                                })
                            }
                        })
                        .catch(error => {
                            //console.log(error.message)
                            this.$bvToast.toast(error.message, {
                                title: 'Кате',
                                variant:"danger",
                                autoHideDelay: 5000
                            })
                        }) 
                    }
                }
            },
            async removeVehicle(licencePlate){
                if(this.accountNumber.length>0 && licencePlate.length>0){

                    let sig = await this.signForAction();
                    if(sig!=null && sig.length>10){
                        
                        axios.post(apiDomain+'/law/signaction',
                        {"dataBase64":this.dataBase64,"sig":sig,"body":sig},{headers:getHeader()})
                        .then((res)=>{
                            if(res.data=='success'){
                                let warn = confirm(vue.i18n.translate("system.confirmMsg"));
                                if(warn){
                                    axios.post(apiDomain+'/law/removevehicle',{"accountNumber":this.accountNumber,"licencePlate":licencePlate},{headers:getHeader()})
                                    .then(response=>{
                                        if(response.data=='success'){
                                            this.clearForm();    
                                            this.sysChange();
                                            this.$bvModal.hide("carModal");
                                        }
                                    })
                                    .catch(error => {
                                        alert(error.message);
                                    })   
                                }
                            }else{
                                this.$bvToast.toast(vue.i18n.translate("qaj.reg.carSignError"), {
                                    title: 'ERROR',
                                    variant:"danger",
                                    autoHideDelay: 5000
                                })
                            }
                        })
                        .catch(error => {
                            //console.log(error.message)
                            this.$bvToast.toast(error.message, {
                                title: 'Кате',
                                variant:"danger",
                                autoHideDelay: 5000
                            })
                        }) 
                    }
                }
            },
            carInfo(licencePlate){
                if(licencePlate.length>0){
                    // this.$router.push({name:"CarInfo",params:{"plate":licencePlate}})
                    //mobile not supported function open !!!
                    window.open(`/#/carinfo/${licencePlate}`,'_blank');
                }
                
            },
            recordProvider(ctx){ 
                
                ctx.accountNumber=this.accountNumber;
                //alert(ctx.accountNumber);
                this.isBusy = false
                let promise = axios.post(apiDomain+'/law/listaccountcar',ctx,{headers:getHeader()});
                    return promise.then((response) => {
                        const result = response.data;
                        this.isBusy = false
                        this.totalRows=result.totalElements;
                        //alert(JSON.stringify(result.content));
                        return(result.content)
                }).catch(error => {
                    console.log(error)
                    this.isBusy = false
                    return []
                })
            },
            onRecordSelected(items){
                this.selectedRows=items;
            },
            checkKeyDownAlphaNumeric(event) {
                if (!/[a-zA-Z0-9\s]/.test(event.key)) {
                    this.ignoredValue = event.key ? event.key : "";
                    event.preventDefault();
                }
            },
        },
        created(){
            this.accountNumber = this.$route.params.accountNumber;
            
        },
        watch: {
            $route(to, from) {
                //console.log(to);
                this.accountNumber = this.$route.params.accountNumber;
            }
        } 

    }
</script>