<template>
     <b-modal id="depModal" 
        :title="$t('qaj.org.addDep')" 
        hide-footer size="lg" 
        @hidden="clearForm();"
        @shown="listDep()">
        <b-form v-on:submit.prevent="addDep">
            <b-form-row class="mb-3">
                <b-col sm="auto" lg="12">
                    <label for="reason">{{ $t('qaj.org.bin') }}</label>
                    <b-input class="form-control" type='text' disabled :value="bin"/>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="reason">{{ $t('qaj.org.name') }}</label>
                    <span class="form-control" style="min-height:150px" disabled v-html="orgName" ></span>
                </b-col>
                <b-col sm="auto" lg="12">
                    <label for="reason">{{ $t('qaj.org.depName') }}</label>
                    <b-input class="form-control" type='text' required v-model="depForm.depName"/>
                </b-col>
                

                <b-col lg="12">
                    <b-row class="pt-2">
                        <b-col lg="6">
                            <b-button size="sm" block variant="primary" @click="clearForm" class="mr-2" >{{$t("qaj.reg.back")}}</b-button>
                        </b-col>
                        <b-col lg="6">
                            <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.org.addDep")}}</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-form-row>
        </b-form>
        <div class="w-100">
            <b-row class="mb-4">
                <b-col lg="12" class="text-right pb-3">
                     <b-button size="sm" @click="mapUser(orgId)" >{{$t("qaj.depModal.setRoot")}}</b-button>
                </b-col>
                <b-col lg="12" class="text-right">
                     {{$t("qaj.depModal.depCount")}}: {{deps.length}}
                </b-col>
            </b-row>

            <b-row >
                
                <b-col lg="8">{{$t("qaj.depModal.depName")}}</b-col>
                <b-col lg="2">{{$t("qaj.depModal.depChoose")}}</b-col>
                
                <b-col lg="2">{{$t("qaj.depModal.depEdit")}}</b-col>
            </b-row>
            <b-row>
                <b-col lg="12"><hr></b-col>
            </b-row>
            <b-row v-for="(d,di) in deps" :key="di">
                
                <b-col lg="8">{{d.name}}</b-col>
                <b-col lg="2">
                    <b-btn @click="mapUser(d.organizationId)"
                     :variant="d.selected==1 ? 'success' : 'secondary'"
                     size="sm">{{$t("qaj.depModal.choose")}}</b-btn>
                </b-col>
                <b-col lg="2">
                    <b-icon size="xs" @click="getCurrentDep(d)" icon="pencil"></b-icon>
                </b-col>
                <b-col lg="12">
                    <hr>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import vue from 'vue';

    export default{
        props:["orgId","bin","orgName"],
        name: "DepModal",
        data(){
            return {
                depForm:{
                    organizationId:"",
                    bin:"",
                    depName:""
                },
                deps:[]
            }
        },
        methods:{
            mapUser(selectedOrgId){
                
                let warn = confirm(vue.i18n.translate("system.confirmMsg"));
                if(warn && this.bin.length>5){

                    axios.post(apiDomain+'/law/mapuser',{"organizationId":selectedOrgId,"bin":this.bin},{headers:getHeader()})
                    .then((res)=>{
                        this.listDep();
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        }) 
                    })  
                }
            },
            addDep(){
                
                let endPoint = this.depForm.organizationId.length==0 ?  '/law/adddepartment' : '/law/updatedepartment';
                this.depForm.organizationId= this.depForm.organizationId.length==0 ?  this.orgId : this.depForm.organizationId;
                
                this.depForm.bin = this.bin;
                
                if(this.depForm.organizationId.length>5 && this.depForm.depName.length>1){
                    axios.post(apiDomain+endPoint,this.depForm,{headers:getHeader()})
                    .then((res)=>{
                        this.clearForm();
                        this.listDep();
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        })
                    }) 
                }
            },
            
            getCurrentDep(dep){
                this.depForm.organizationId=dep.organizationId;
                this.depForm.depName = dep.name;
                
            },
            clearForm(){
                this.depForm.depName="";
                this.depForm.organizationId="";
                this.deps=[];
            },
            listDep(){
                if(this.bin.length>0){
                    axios.post(apiDomain+'/law/listdepartment',{"bin":this.bin},{headers:getHeader()})
                    .then((res)=>{
                        this.deps=res.data;
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        }) 
                    })  
                }
                
            }
        }
    }
</script>