<template>
    <div class="main-footer">
        <div class="footer-info-out py-2">
            <b-row>
                <b-col lg="12" class="text-center">
                    <h3 style="color: #0079c2">{{$t("qaj.org.footerTitle")}}</h3>
                </b-col>
                <b-col lg="3">
                    <div class="icon-out w-100 text-center">
                        <svg style="height: 50px;" role="img" aria-hidden="true" focusable="false" 
                        data-prefix="fas" data-icon="angle-down" 
                        class="svg-inline--fa fa-angle-down fa-w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>
                    </div>
                    <div class="footer-item pt-4">{{ $t("qaj.org.info1") }}</div>
                </b-col>
                <b-col lg="3">
                    <div class="icon-out w-100 text-center">
                        <svg style="height: 50px;" role="img" aria-hidden="true" focusable="false" 
                        data-prefix="fas" data-icon="angle-down" 
                        class="svg-inline--fa 
                        fa-angle-down fa-w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>
                    </div>
                    <div class="footer-item">{{ $t("qaj.org.info2") }}</div>
                </b-col>
                <b-col lg="3">
                    <div class="icon-out w-100 text-center">
                        <svg style="height: 50px;" role="img" aria-hidden="true" focusable="false" 
                        data-prefix="fas" data-icon="angle-down" 
                        class="svg-inline--fa fa-angle-down fa-w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>
                    </div>
                    <div class="footer-item">{{ $t("qaj.org.info3") }}</div>
                </b-col>
                <b-col lg="3">
                    <div class="icon-out w-100 text-center">
                        <svg style="height: 50px;" role="img" aria-hidden="true" focusable="false" 
                        data-prefix="fas" data-icon="angle-down" 
                        class="svg-inline--fa fa-angle-down fa-w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>
                    </div>
                    <div class="footer-item">{{ $t("qaj.org.info4") }}</div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
    export default {
        name:"MainFooter"
    }
</script>
<style scoped>
    .icon-out{
        color:#0079c2;
    }
    .main-footer{
        width: 100%;
        height: 250px;
        
        background-color: rgba(255, 255, 255, 0.7);
        position: absolute;
        bottom: 0;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
      
    }
    .footer-info-out{
        width: 70%;
        margin:auto;
    }
    .footer-item{
        
        width: 100%;
        background-color: #fff;
        min-height: 100px;
        padding: 10px;
        text-align: center;
        color: #0079c2;
        border: 1px solod #fff;
        border-radius: 10px;
      
    }
</style>