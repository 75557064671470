<template>

    <b-row>
        <b-col>
            <b-table 
                ref="legalRef"
                striped hover selectable
                :items="recordProvider" 
                :fields="fields"
                :busy.sync="isBusy"
                :current-page="currentPage"
                :per-page="perPage"
                table-variant='light'
                :filter="filter"
                :select-mode="selectMode"
                selected-variant="primary"
            
                @row-selected="onRecordSelected"
                >
                <template v-slot:cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <template v-slot:table-busy>
                <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Жүктелуде...</strong>
                </div>
                </template>

                <template #head(selected)="data">
                    <span aria-hidden="true">&check;</span>
                </template>

                <template #head(accountNumber)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(balance)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(contractNumber)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
               
                <template #head(createdDate)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(actions)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template v-slot:cell(actions)="row">
                    <b-btn variant="success" 
                        class="ml-1" size="xs" @click="carList(row.item.accountNumber)" >{{ $t("qaj.legal.cars").toUpperCase() }}</b-btn>

                    <b-btn variant="secondary" 
                        class="ml-1" size="xs" @click="chargeList(row.item.accountNumber)" >{{ $t("qaj.legal.charging").toUpperCase() }}</b-btn>
                </template>
               
            </b-table>
        </b-col>
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import DocModal from "@/components/law/DocModal"
    import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext"
    import vue from 'vue';
    export default {
        name : "LegalAccount",
        data(){
            return {
                accounts:[],
                //"balance": 0, "createdDate": "2023-10-23T10:07:22.402Z", "modifiedDate": "2023-10-23T10:07:22.402Z", "contractNumber": "308/23 от 23.10.2023г."
                fields: [
                   
                    {
                        key: 'accountNumber',
                        label: 'qaj.legal.accountNumber',
                        thStyle: { width: "20%" } ,
                        
                    },
                    {
                        key: 'balance',
                        label: 'qaj.legal.balance',
                    
                    },
                    {
                        key: 'contractNumber',
                        label: 'qaj.legal.contractNumber',
                        variant:'secondary'
                    },
                
                    {
                        thStyle: { width: "30%" } ,
                        key:"createdDate",
                        label:"qaj.legal.createdDate",
                       
                    },
                    {
                        thStyle: { width: "30%" } ,
                        key:"actions",
                        label:"qaj.org.actions",
                        variant:'secondary'
                    },
                ],
                isBusy:false,
                totalRows:0,
                currentPage: 1,
                perPage: 20,
                tableVariant:'light',
                filter:"",
                selectMode:'single',
                selectedRows:[],
            }
        },
        methods:{
            carList(accountNumber){
                this.$router.push({name:"CarList",params:{"accountNumber":accountNumber}})
            },
            chargeList(accountNumber){
                this.$router.push({name:"Charging",params:{"accountNumber":accountNumber}})
            },
            recordProvider(ctx){
                ctx.status = this.status;
                
                this.isBusy = false
                let promise = axios.post(apiDomain+'/law/listaccount',ctx,{headers:getHeader()});
                    return promise.then((response) => {
                    const result = response.data;
                    this.isBusy = false
                    this.totalRows=result.content.length;
                    //alert(JSON.stringify(result));
                    return(result.content)
                }).catch(error => {
                    this.isBusy = false
                    return []
                })
            },
            onRecordSelected(items){
                this.selectedRows=items;
            },
        }

    }
</script>