<template>
    <b-container fluid class="main-container"
     style="margin: 0;padding: 0;padding-top: 20px;">
     
        <b-card no-body class="overflow-hidden" style="max-height: 55%;min-height: 55%; width: 70%; margin: auto;">
            <b-row class="p-4">
                <b-col lg="7">
                    <div class="w-100 text-center">
                        <img  src="@/assets/login-logo.png" alt=""  height="50">
                        <img  src="@/assets/qaj_logo.png" alt=""  height="50">
                    </div>
                    <div class="intro-out mt-4" v-if="locale=='kz'">
                        Заңды тұлғалар мен жеке кәсіпкерлер үшін «ҚазАвтоЖол» ҰК АҚ жеке кабинетіне қош келдіңіз.
                        Егер сіз бұрын жеке кабинетті пайдаланбаған болсаңыз, онда «Тіркелу» түймесін басып, жүйенің нұсқауларын орындаңыз. Жеке кабинеттің функцияларын пайдалану Сіздің заңды тұлғаға/ЖК-ге қатысқаныңызды растайтын ЭЦҚ болған жағдайда ғана мүмкін болады.
                        Тіркеу процедурасының және жүйенің басқа функцияларының толық сипаттамасы <br>
                        <a href="https://docs.google.com/document/d/1cVgA_3u0y7nYk9zYlfd09zLV8LQNQLHQxYchdMgfDSU/edit?usp=sharing" target="_blank">пайдаланушы нұсқаулығында берілген</a>
                    </div>
                    <div class="intro-out mt-4" v-if="locale=='ru'">
                        Добро пожаловать в Личный кабинет АО НК «КазАвтоЖол» для юридических лиц и индивидуальных предпринимателей.
                        Если вы не пользовались ранее Личным кабинетом, то, пожалуйста, нажмите «Зарегистрироваться» и следуйте инструкциям системы. Использование функций Личного кабинета возможно только при наличии ЭЦП, подтверждающего Вашу причастность к юридическому лицу/ИП.
                        Подробное описание процедуры регистарции и других функций системы находится в 
                        <a href="https://docs.google.com/document/d/1cVgA_3u0y7nYk9zYlfd09zLV8LQNQLHQxYchdMgfDSU/edit?usp=sharing" target="_blank">Руководстве пользователя</a>
                    </div>
                   <!-- <div v-if="locale=='ru'" style="background-color: #f8f9fa; padding: 15px; border: 1px solid #dee2e6; border-radius: 5px; font-size: 14px;">
                     <h2 style="font-size: 16px;">Уважаемые пользователи!</h2>
                     <p>
                        В период с 23:00 ч. 21-октября по 05:00 ч. 22-октября будут проводиться плановые работы по обновлению в личном кабинете KazToll <a href="https://kaztoll.kz/#/"></a>, приложении Sapar, на официальном сайте <a href="https://ru.qaj.kz/"></a>, а так же в платежных системах. В связи с чем, уведомляем вас о необходимости заблаговременно пополнить лицевые счета транспортных средств.
                     </p>
                     <p>
                        Работы по обновлению необходимы для обеспечения корректного функционирования информационных систем. Приносим извинения за временные неудобства!
                     </p>
                   </div>
                   <div v-if="locale=='kz'" style="background-color: #f8f9fa; padding: 15px; border: 1px solid #dee2e6; border-radius: 5px; font-size: 14px;">
                     <h2 style="font-size: 16px;">Құрметті пайдаланушылар!</h2>
                     <p>
                        2024 жылғы 21-қазан сағат 23:00-ден бастап 22-қазан сағат 05:00-ге дейінгі аралықта <a href="https://kaztoll.kz/#/">KazToll</a> жеке кабинеті, Sapar қосымшасы, ресми сайт <a href="https://kz.qaj.kz/">qaj.kz</a> және төлем жүйелерінде жоспарлы жаңарту жұмыстары жүргізіледі.  Сол себепті, автокөліктің жеке балансын алдын-ала толықтыру қажеттілігі туралы ескертеміз.  
                     </p>
                     <p>
                        Жоспарлы жаңартулар ақпараттық жүйелердің дұрыс жұмыс жасауы үшін қажет міндетті шаралардың бірі болып табылады. Уақытша қолайсыздықтар үшін кешірім сұраймыз!
                     </p>
                   </div> -->
                </b-col>
                <b-col lg="5">
                    <b-row>
                        <b-col lg="6">
                            <b-button variant="outline-success" block @click="$i18n.set('kz')">
                                Қазақша
                            </b-button>
                        </b-col>
                        <b-col lg="6">
                            <b-button variant="outline-primary" block @click="$i18n.set('ru')">
                                Русский
                            </b-button>
                        </b-col>
                   </b-row>
                   <h3 class="text-center mt-3" style="color: #0079c2;">
                        {{ $t("qaj.org.account") }}
                   </h3>
                   <div class="w-100 mt-3">
                        <b-button variant="success" @click="etspToken()" block>{{ $t("qaj.org.account") }}</b-button>
                   </div>
                   
                   <div class="w-100 mt-3">
                        <b-button variant="primary" 
                        @click="orgModal()"
                         block>{{ $t("qaj.org.etsp") }}</b-button>
                   </div>

                   <div  class="w-100 mt-5" v-if="locale=='ru'">
                    При возникновении технических проблем или других вопросов обратитесь 
                    в Контакт-Центр по номеру <strong>1403</strong> или оставьте обращение в Открытой линии на сайте 
                    <a href="http://www.qaj.kz" target="_blank">
                        QAJ.KZ
                    </a>

                   </div>
                   <div  class="w-100 mt-5" v-if="locale=='kz'">
                    Егер техникалық проблемалар немесе басқа сұрақтар туындаса, <strong>1403</strong> нөмірі бойынша 
                    байланыс орталығына хабарласыңыз немесе өтінішті сайтта ашық желіде қалдырыңыз 
                    <a href="http://www.qaj.kz" target="_blank">
                        QAJ.KZ
                    </a>

                   </div>
                </b-col>
            </b-row>
        </b-card>
        <SecFooter></SecFooter>
        <MainFooter></MainFooter>
        <RegModal></RegModal>    
    </b-container>
</template>
<script>
    import axios from 'axios';
    import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext";
    import {getHeader,apiDomain} from "@/config/config";
    import vue from 'vue';
    import RegModal from '@/components/lawlanding/RegModal'
    import MainFooter from '@/components/lawlanding/MainFooter'
    import SecFooter from "@/components/lawlanding/SecFooter.vue";
    const authUser = {};
    export default{

        name:"LawMain",
        components:{
            SecFooter,
            RegModal,
            MainFooter
        },
        data(){
            return {
                flocale:"kz",
                eloginData :{
                    connectionId: "",
                    xmlSignature: "",
                    password: "",
                    loginType:99
                },
            }
           
        },
        methods:{
            etspToken() {
                //alert("this called first ");
                axios.post(apiDomain + "/etsptoken", {}, {headers: getHeader()})
                .then(res => {
                    //alert(JSON.stringify(res.data));
                    console.info("came from golang ",res.data);
                    this.xmlSignature(res.data);
                })
                .catch(error => {
                    alert(error.message)
                });
            },
            async xmlSignature(res) {
                let NCALaClient = new NCALayerClientExtension();
                console.log("nc object ", NCALaClient);
                try {
                    await NCALaClient.connect();
                } catch (error) {
                    alert(error.message);
                    return
                }
                try {
                    let XMLignature = await NCALaClient.sign('xml', null, res.xml, 'auth', this.$i18n.locale, false)
                    // let XMLignature = await  NCALaClient.signXml('PKCS12', res.xml, 'AUTH')
                    this.eloginData.xmlSignature = XMLignature[0];
                    this.eloginData.connectionId = res.connectionId;
                    console.info("xml signature ", XMLignature);
                    //alert("beku");
                    this.sendLoginData();
                } catch (error) {
                    alert(error.message);
                }
            },
            sendLoginData() {
                if (this.eloginData.connectionId.length > 4 && this.eloginData.xmlSignature.length > 10) {
                    axios.post(apiDomain + "/etspverify", this.eloginData, {headers: getHeader()})
                    .then(res => {
                        if (res.status === 200) {
                            authUser.access_token = res.data.access_token;
                            authUser.refresh_token = res.data.refresh_token;
                            window.localStorage.setItem('authUser', JSON.stringify(authUser));
                            this.$router.push({name: 'AfterAuth'});
                        }
                        
                    })
                    .catch(error => {
                        console.log("login error",error)
                      console.log()
                        if(error.response && error.response.status){
                            let st = error.response.status;
                            if(st==999){
                                alert("already registered");
                            }else if (st==745){
                                alert(vue.i18n.translate('qaj.reg.idnNotSame'));
                            }
                            else{
                                alert(vue.i18n.translate('qaj.reg.notRegisterUser'));
                            }
                        }else{
                            alert(vue.i18n.translate('qaj.reg.mailError')); 
                        }
                        
                    });
                }
            },
            orgModal(){
                this.$bvModal.show("orgModal");
            },
           
        },
        computed:{
            locale:function(){
                return vue.i18n.locale();    
            },
        },
       
    }
</script>
<style scoped>
    .intro-out{
        color: #525f7f;
        line-height: 1.7;
    }
   
    .main-container{
        background: url("../assets/slide.287ff700f85e8bb953a7.jpg") no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100vh;
        position: relative;
    }
    .underflow-hidden{
      text-align: center;
      margin-top: 15px;
    }
    
</style>