<template>
    <main class="auth auth-floated" style="width:50%;margin:auto;">
      <b-row class="pt-4">
        <b-col lg="6">
          <b-button variant="outline-success" block @click="$i18n.set('kz')">
            Қазақша
          </b-button>
        </b-col>
        <b-col lg="6">
          <b-button variant="outline-primary" block @click="$i18n.set('ru')">
            Русский
          </b-button>
        </b-col>
      </b-row>
      <form class="auth-form" v-on:submit.prevent="handleLoginFormSubmit">
        <div class="mb-4 mt-5">
          <div class="mb-3 text-center">
              <img  src="@/assets/login-logo.png" alt=""  height="72">
            <hr>
          </div>
          <h1 class="h3 text-center"> {{$t('qaj.reg.login')}} </h1>
        </div>
        <div class="form-group mb-4">
          <label class="d-block text-left" for="inputUser">{{$t('qaj.reg.email')}}</label>
          <input 
            type="text" 
            id="inputUser" 
            class="form-control form-control-lg" 
            required="" 
            v-model="login.username"
            autofocus="">
        </div>
        <div class="form-group mb-4">
          <label class="d-block text-left" for="inputPassword">{{$t('qaj.reg.pCode')}}</label>
          <input 
            type="password" 
            id="inputPassword" 
            class="form-control form-control-lg"
            required=""
            v-model="login.password"
            >
        </div>
        <div class="form-group mb-4">
          <button class="btn btn-lg btn-primary btn-block" type="submit">{{$t('qaj.reg.login')}}</button>
        </div>
      </form>
      <SecFooter></SecFooter>
    </main>
</template>
<script>
  import axios from 'axios';
  import {getHeader,loginUrl} from "../config/config";
  import SecFooter from "@/components/lawlanding/SecFooter.vue";
 
  const authUser = {};
  export default {
       name : "Login",
    components: {SecFooter},
       data(){
        return {
          login:{
            username:'',    
            password:''
          }
        }
      },
      methods:{
        handleLoginFormSubmit(){
          //alert(JSON.stringify(header));
          axios.post(loginUrl,{
            'username':this.login.username,
            'password':this.login.password
          }, {headers: getHeader()})
          .then((res)=>{
            if(res.status===200){
              authUser.access_token=res.data.access_token;
              authUser.refresh_token = res.data.refresh_token;
              window.localStorage.setItem('authUser',JSON.stringify(authUser));
              this.$router.push({name:'AfterAuth'});
            }
            if(res.status===401){
              alert("Сіз енгізген ақпарат дұрыс емес.");
            }
          })
          .catch(error => {
              alert("Сіз енгізген ақпарат дұрыс емес.");

          });
        }
        
      }        
  }
</script>
<style scoped>

</style>