<template>
  <div>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="fixed-top" style="height: 80px;">
    <b-navbar-brand href="#">{{$t('logoName')}}</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>

      <b-navbar-nav class="text-uppercase">

        <b-nav-item 
          v-if="hasRole('ADMIN') || hasRole('OPERATOR')" 
          href="/#/organizations">
          <span class="text-warning font-weight-bold">
            {{$t('qaj.org.organizations')}}
          </span>
        </b-nav-item>
        <b-nav-item  
        v-if="hasRole('ADMIN') || hasRole('OPERATOR')" 
        href="/#/organizationusers">
          {{$t('mainMenu.users')}}
        </b-nav-item>
        <b-nav-item  
        v-if="hasRole('ADMIN') || hasRole('OPERATOR')" 
        href="/#/users">
          {{$t('mainMenu.documents')}}
        </b-nav-item>
        

       

        <b-nav-item  
          v-if="hasRole('USER')" 
          href="/#/useraccount">
          {{$t('qaj.acc.myDocs')}}
        </b-nav-item>
        <b-nav-item  
          v-if="hasRole('USER')" 
          href="/#/accounts">
          <span class="text-warning font-weight-bold">{{$t('qaj.acc.accounts')}}</span>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items there -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right :text="$t('mainMenu.currentLang')">
          <b-dropdown-item href="javascript:void(0)"  v-for="locale in $i18n.locales()" :key="'i81n-' + locale"
			      @click="$i18n.set(locale)" v-if="$i18n.locale() !== locale">{{locale}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right :text="loginedUser.firstName">
          <!-- Using 'button-content' slot -->
          <b-dropdown-item href="javascript:void(0)" @click="logOut">
            {{$t('logOut')}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>  
</template>

<script>
import {mapActions,mapState} from 'vuex';
import axios from 'axios';
import {apiDomain,getHeader} from "../config/config";

export default {
  name: 'AppHeader',
  data(){
    return {
      statisticData:null,
      showOrderLoanModal : false,
      salaryInfo:[],
    }
  },
  
  methods:{
    showLoanModal(){
      this.showOrderLoanModal=true;
      this.$bvModal.show("orderLoanModal");
    },
    
    
    logOut : function(){
        axios.post(apiDomain+'/syslogout',{},{headers:getHeader()})
        .then(()=>{
            window.localStorage.removeItem("authUser");
            window.localStorage.clear();
            //this.logoutSystem()
            this.$router.push({name:'Main'});
        })
        .catch(error => {
            if(error.response.status==401){
              window.localStorage.removeItem("authUser");
              window.localStorage.clear();
              //this.logoutSystem()
              this.$router.push({name:'Main'});
              return;
            }
            alert(error.message);
        })
       
    },
    ...mapActions([
        'logoutSystem',
    ]),
   
  
    hasRole(roleName){
      if(this.loginedUser.userRoles){
        let roleNames = this.loginedUser.userRoles;
        if(roleNames.length>0){
          //alert(JSON.stringify(roleNames));
          for(let r=0;r<roleNames.length;r++){
            
            if(roleNames[r].name===roleName){
              return true;
            }
          }
        }
        else{
          return false;
        }
      }
      return false;
      
      
    }
  },
  created(){
    if(this.hasRole("ADMIN") || this.hasRole("USER")){
      
    }else{
      window.location.href = "/#/login";
    }
  },
  computed:{
    ...mapState([
        'loginedUser'
    ]),
  }
}
</script>
<style scoped>
  .pulse {
  
    display: inline-block;
    margin-top: 5px;

    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 1s infinite;
  }
  .pulse:hover {
    animation: none;
  }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
