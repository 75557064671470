<template>

    <b-row>
        <b-col>
            <b-table 
                ref="legalRef"
                striped hover selectable
                :items="recordProvider" 
                :fields="fields"
                :busy.sync="isBusy"
                :current-page="currentPage"
                :per-page="perPage"
                table-variant='light'
                :filter="filter"
                :select-mode="selectMode"
                selected-variant="primary"
            
                @row-selected="onRecordSelected"
                >
                
                <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Жүктелуде...</strong>
                    </div>
                </template>
                <template #head(createdDate)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(amount)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(sourceGroup)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(sourceName)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
            </b-table>
        </b-col>
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import DocModal from "@/components/law/DocModal"
    import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext"
    import vue from 'vue';
    export default {
        name : "Charging",
        data(){
            return {
                accounts:[],
                
                fields: [
                    {
                        key: 'createdDate',
                        label: 'qaj.charging.createdDate',
                    
                    },
                    {
                        key: 'amount',
                        label: 'qaj.charging.amount',
                        variant:'secondary'
                    },
                    {
                        key:"sourceGroup",
                        label:"qaj.charging.sourceGroup",
                       
                    },
                    {
                        key:"sourceName",
                        label:"qaj.charging.sourceName",
                       
                    },
                   
                ],
                isBusy:false,
                totalRows:0,
                currentPage: 1,
                perPage: 20,
                tableVariant:'light',
                filter:"",
                selectMode:'single',
                selectedRows:[],

                accountNumber:""
            }
        },
        methods:{
            recordProvider(ctx){
                ctx.status = this.status;
                
                this.isBusy = false
                ctx.accountNumber=this.accountNumber;
                if(ctx.accountNumber.length>3){
                    let promise = axios.post(apiDomain+'/law/charging',ctx,{headers:getHeader()});
                    return promise.then((response) => {
                        const result = response.data;
                        this.isBusy = false
                        this.totalRows=result.content.length;
                        //alert(JSON.stringify(result));
                        return(result.content)
                    }).catch(error => {
                        this.isBusy = false
                        return []
                    })
                }
                
            },
            onRecordSelected(items){
                this.selectedRows=items;
            },
        },
        created(){
            this.accountNumber = this.$route.params.accountNumber;
              
        },
        watch: {
            $route(to, from) {
                //console.log(to);
                this.accountNumber = this.$route.params.accountNumber;
            }
        } 

    }
</script>