<template>
    <b-modal id="docModal" 
        :title="$t('qaj.acc.newDoc.title')" 
        hide-footer size="lg" 
        @show="modalShown()"
        @hidden="clearForm();">
        <b-overlay :show="showOverlay" rounded="sm">
        <b-form v-on:submit.prevent="createDoc">
            <b-form-row class="mb-3">
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="userPosition" 
                    class="form-control-sm" :placeholder="$t('qaj.acc.newDoc.userPosition')" v-model="docForm.userPosition"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="userFio" :placeholder="$t('qaj.acc.newDoc.userFio')"
                    class="form-control-sm" v-model="docForm.userFio"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="userDoc" :placeholder="$t('qaj.acc.newDoc.userDoc')"
                    class="form-control-sm" v-model="docForm.userDoc"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-textarea required id="legalAddress" :placeholder="$t('qaj.acc.newDoc.legalAddress')"
                    class="form-control-sm" v-model="docForm.legalAddress"></b-form-textarea>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-textarea required id="factAddress" 
                    :placeholder="$t('qaj.acc.newDoc.factAddress')" 
                    class="form-control-sm" v-model="docForm.factAddress"></b-form-textarea>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input type="email" 
                    required id="email" :placeholder="$t('qaj.acc.newDoc.email')" class="form-control-sm" v-model="docForm.email"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="iik" :placeholder="$t('qaj.acc.newDoc.iik')" class="form-control-sm" v-model="docForm.iik"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="kbe" :placeholder="$t('qaj.acc.newDoc.kbe')" class="form-control-sm" v-model="docForm.kbe"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="bik" :placeholder="$t('qaj.acc.newDoc.bik')" class="form-control-sm" v-model="docForm.bik"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="bank" :placeholder="$t('qaj.acc.newDoc.bank')" class="form-control-sm" v-model="docForm.bank"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input required id="phone"  :placeholder="$t('qaj.acc.newDoc.phone')"  class="form-control-sm" v-model="docForm.phone"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <b-form-input  required id="website" :placeholder="$t('qaj.acc.newDoc.website')" class="form-control-sm" v-model="docForm.website"/>
                </b-col>
                <b-col sm="auto" lg="12" class="pb-2">
                    <div style="border:1px dashed #e68420;padding:10px;border-radius: 5px;">
                        <b-row class="mb-2">
                            <b-col lg="5">
                                {{ !docForm.assetName ? $t("qaj.acc.newDoc.file.title") : $t("qaj.acc.newDoc.file.titleChange")}}
                            </b-col>
                            <b-col lg="7">
                                
                                <b-alert show variant="secondary" v-if="docForm.assetName"><span style="font-size: 12px;">{{ docForm.assetName}}</span></b-alert>
                                <b-form-file
                                v-model="file1"
                                accept=".zip, .rar"
                                size="sm"
                                :state="Boolean(file1)"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                ></b-form-file>
                            </b-col>
                        </b-row>
                        <b-alert show variant="warning">
                          <span style="font-size: 10px;">  {{ $t("qaj.acc.newDoc.file.information") }} <br><b style="color: red">{{ $t("qaj.acc.newDoc.file.varInfo") }}</b></span>
                        </b-alert>
                    </div>
                </b-col>
                <b-col lg="12">
                    <b-row class="pt-2">
                        <b-col lg="6">
                            <b-button size="sm" block variant="primary" @click="clearForm" class="mr-2" >{{$t("qaj.reg.back")}}</b-button>
                        </b-col>
                        <b-col lg="6">
                            <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.acc.newDoc.save")}}</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-form-row>
        </b-form>
        </b-overlay>
    </b-modal>
</template>
<script>
import axios from 'axios';
import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext";
import {getHeader,apiDomain,getMultipartHeader} from "@/config/config";
import vue from 'vue';
const authUser = {};
export default{
    name:"DocModal",
    props:["rootOrgDocs"],
    data(){
        return {
            showOverlay:false,
            docForm:{   
                organizationId:0,
                documentId:0,
                userPosition:"",
                userFio:"",
                userDoc:"",
                legalAddress:"",
                factAddress:"",
                email:"",
                iik:"",
                kbe:"",
                bik:"",
                bank:"",
                phone:"",
                website:"",

                assetName:"",
                assetId:"",
                documentId:""
            },
            file1:null,
        
        }
    },
    methods: {
        modalShown(){
            this.rootDoc();
        },
        rootDoc(){
            //alert("me is called");
            axios.post(apiDomain+'/law/rootorg',{},{headers:getHeader()})
            .then(response=>{
                this.docForm=response.data;  
            })
            .catch(error => {
                alert(error.message);
            })
        },
      createDoc() {
        const maxFileSize = 20 * 1024 * 1024; // 20 MB in bytes

        if (this.file1 != null) {
          // Check the file size
          if (this.file1.size > maxFileSize) {
            //alert(vue.i18n.translate('qaj.fileSizeErr'));
            this.$bvToast.toast(vue.i18n.translate('qaj.fileSizeErr'), {
              title: ``,
              noCloseButton: false,
              variant: 'danger',
              toaster: 'b-toaster-bottom-left'
            });
            this.file1 = null;
            return;
          }

          let formData = new FormData();
          formData.append("orgJSON", JSON.stringify(this.docForm));
          formData.append("docFile", this.file1);

          this.showOverlay = true;

          axios.post(apiDomain + '/law/updateorg', formData, { headers: getMultipartHeader() })
              .then(response => {
                if (response.data === 'success') {
                  this.showOverlay = false;
                  this.clearForm();
                  this.rootOrgDocs();
                }
              })
              .catch(error => {
                alert(error.message);
              });

        } else {
          this.showOverlay = false;
          alert("Please select a file...");
        }
      },

        clearForm(){
            this.docForm={   
                userPosition:"",
                userFio:"",
                userDoc:"",
                legalAddress:"",
                factAddress:"",
                email:"",
                iik:"",
                kbe:"",
                bik:"",
                bank:"",
                phone:"",
                website:"",
                assetName:"",
                assetId:"",
                documentId:""
            };
            this.file1=null;
            //this.rootOrg();
            this.$bvModal.hide("docModal");
        },
        
    }
}
</script>