<template>

    <b-row>
        <b-col cols="12">
            <b-alert show v-if="dto.status=='CHECKING'" variant="secondary" v-html="$t('qaj.org.checking')"></b-alert>
            <b-alert show v-if="dto.status=='PASSED'" variant="primary" v-html="$t('qaj.org.passed')" ></b-alert>
            <b-alert show v-if="dto.status=='APPROVED'" variant="info" v-html="$t('qaj.org.approved')"></b-alert>
            <b-alert show v-if="dto.status=='PENDING'" variant="warning" v-html="$t('qaj.org.pending')"></b-alert>
            <b-alert show v-if="dto.status=='SIGNED'" variant="success" v-html="'Договор'+': '+dto.contractNumber+'<br>'+$t('qaj.org.signed')+'<br>от '+dto.createdDate"></b-alert>
            <b-alert show v-if="dto.status=='DECLINE'" variant="danger" v-html="$t('qaj.org.decline')"></b-alert>
        </b-col>
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import vue from 'vue';
    export default {
        name : "Check",
        data(){
            return {
                dto:{},
                documentId:""
            }
        },
        methods:{
            checkStatus(pId){
               
                axios.post(apiDomain+'/law/docstatus',{"documentId":pId})
                .then(response=>{
                    this.dto=response.data;
                })
                .catch(error => {
                    alert(error.message);
                })       
            } 
           
        },
        created(){
            vue.i18n.set('ru');
            this.documentId = this.$route.params.documentId;
            this.checkStatus(this.documentId);
        },
        watch: {
            $route(to, from) {
                vue.i18n.set('ru');
                this.documentId = this.$route.params.documentId;
                this.checkStatus(this.documentId);
            }
        } 

    }
</script>