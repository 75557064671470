<template>
        <b-row>
            <b-col lg="12" class="px-5">
                <div class="px-5">
                    <b-overlay :show="showOverlay" rounded="sm">
                        <!-- { "id": "f674d497-9d8d-461f-bc58-a29fa241b9fe", "firstName": "М.", 
                        "lastName": "Шахарбаев", "middleName": "Б.", "email": "operator@qaj.kz", "position": "Заместитель директора филиала" } -->

                        <!-- {{userForm}} -->
                        <b-form v-on:submit.prevent="updateUser">
                            <b-form-row class="mb-3">
                                <b-col sm="auto" lg="12" class="pb-2">
                                    <b-form-input required id="firstName" 
                                    class="form-control-sm" :placeholder="$t('qaj.comp.firstName')" v-model="userForm.firstName"/>
                                </b-col>
                                <b-col sm="auto" lg="12" class="pb-2">
                                    <b-form-input required id="lastName" 
                                    class="form-control-sm" :placeholder="$t('qaj.comp.lastName')" v-model="userForm.lastName"/>
                                </b-col>
                                <b-col sm="auto" lg="12" class="pb-2">
                                    <b-form-input required id="middleName" 
                                    class="form-control-sm" :placeholder="$t('qaj.comp.middleName')" v-model="userForm.middleName"/>
                                </b-col>
                                <b-col sm="auto" lg="12" class="pb-2">
                                    <b-form-input type="email" required id="email" 
                                    class="form-control-sm" :placeholder="$t('qaj.comp.email')" disabled v-model="userForm.email"/>
                                </b-col>
                                <b-col sm="auto" lg="12" class="pb-2">
                                    <b-form-input required id="position" 
                                    class="form-control-sm" :placeholder="$t('qaj.comp.position')" v-model="userForm.position"/>
                                </b-col>
                                <b-col sm="auto" lg="12" class="pb-2">
                                    <b-form-input required id="operatorDoc" 
                                    class="form-control-sm" :placeholder="$t('qaj.comp.operatorDoc')" v-model="userForm.operatorDoc"/>
                                </b-col>
                                
                                <b-col lg="12">
                                    <b-row class="pt-2">
                                        <b-col lg="6">
                                            <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.acc.newDoc.save")}}</b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-form-row>
                        </b-form>
                    </b-overlay>
                </div>
                
            </b-col>
        </b-row>
       
    
</template>
<script>
import axios from 'axios';

import {getHeader,apiDomain,getMultipartHeader} from "@/config/config";
import vue from 'vue';
export default{
    name:"CompUser",
    
    data(){
        return {
            showOverlay:false,
            userForm:{   
                id:"",
                firstName:"",
                lastName:"",
                middleName:"",
                position:"",
                operatorDoc:""
            },
            
        
        }
    },
    methods: {
        
        updateUser() {
          alert(vue.i18n.translate('qaj.systems.confirmMsg'));
          
          axios.post(apiDomain + '/law/updatecomuser', this.userForm, {headers: getHeader()})
                .then((res) => {
                  this.$bvToast.toast('Данные успешно сохранены', {
                    title: 'Успешно',
                    autoHideDelay: 5000,
                    variant: 'success'
                  })
                })
                .catch(error => {
                  this.$bvToast.toast(error.message, {
                    title: 'Оишбка',
                    autoHideDelay: 5000,
                    variant: 'danger'
                  })
                });
        },
        clearForm(){
            this.userForm={   
                id:"",
                firstName:"",
                lastName:"",
                middleName:"",
                position:"",
                operatorDoc:""
            }
           
           
        },
        //currcomp
        currentCompUser(){
            axios.post(apiDomain+'/law/currcomp',{},{headers:getHeader()})
            .then((res)=>{
                this.userForm=res.data;
            })
            .catch(error => {
                //console.log(error.message)
                this.$bvToast.toast(error.message, {
                    title: 'Кате',
                    autoHideDelay: 5000
                })
            }) 
        }   
    },
    created(){
        this.currentCompUser();
    }
}
</script>