<template>
  <router-view></router-view>
</template>
<script>
  export default {
    name: 'app'
  }
</script>
<style >
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
   url(./fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
}


</style>

