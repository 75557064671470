// Load environment variables from .env file
export const  apiDomain = process.env.VUE_APP_ROOT_API;
export const  backendDomain = "http://16.171.26.14:8080";
export const  webSocket = "ws://16.171.26.14:8080";
//export const apiDomain="";

export const loginUrl = apiDomain+"/syslogin";

//
export const getHeader = function(){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  if(tokenData){
    const headers= {
        'Accept':'application/json',
        'Authorization':'Bearer ' + tokenData.access_token
    }
    return headers;
  }
  else {
      return null;
  }
}
export const getHeaderT = function(){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  if(tokenData){
    const headers= {
        'Accept':'application/json',
        'Authorization':'Bearer ' + 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4NDAxMTczNTAwNjciLCJiaW4iOiIxNDAyNDEwMTQ0MTYiLCJyb2xlcyI6WyJST0xFX0NPTlRSQUNUT1IiXSwiZXhwIjoxNzA4MDg3NzAwLCJpYXQiOjE3MDcyMjM3MDAsImlpbiI6Ijg0MDExNzM1MDA2NyJ9.b8UAzRyRf7kz08he9rN1BFiPko-VntiA1XGBr1DQ8cY'
    }
    return headers;
  }
  else {
      return null;
  }
}
export const downloadHeader = function(){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  if(tokenData){
    const headers= {
        'Accept':'application/json',
        'Authorization':'Bearer ' + tokenData.access_token,        
    }
    return headers;
  }
  else {
      return null;
  }
}

export const getXlsHeader = function(){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  const headers= {
    'Accept': 'application/vnd.openxmlformats-officedocument'
           + '.spreadsheetml.sheet',
    'Authorization':'Bearer ' + tokenData.access_token
  }
  return headers;
}
export const getMultipartHeader = function (){
  const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
  
  const headers= {
    'Content-Type': 'multipart/form-data',
    'Authorization':'Bearer ' + tokenData.access_token
  }
  return headers;

}

export const testFunction= function(){
  //alert("");
  //let u go main test 
  //console.log("ene bol busgui chini");  

}
