<template>
  <div class="spinner-container">
    <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
  </div>
</template>
<script>
    import axios from 'axios';
    import {mapActions} from 'vuex';
    import vue from 'vue';
    import {apiDomain,getHeader} from "../config/config";
    import { BToast } from 'bootstrap-vue';
    export default {
        name:"Auth",
        methods:{
            ...mapActions([
                'setLoginedUser'
            ]),
            getLoginedUser(result){
                window.localStorage.setItem("loginedUser",JSON.stringify(result));
                console.log(result);
                this.setLoginedUser();
                result.userRoles.forEach(r => {
                    if(r.name=='OPERATOR' || r.name=='ADMIN'){
                        this.$router.push({ path: '/organizations', query: { toast: 'success' } });
                        // window.location.href = "/#/organizations";
                        return;
                    }
                    if(r.name=='USER'){
                        //alert("the user has caught");
                        this.$router.push({ path: '/useraccount', query: { toast: 'success' } });
                      //window.location.href = "/#/useraccount";
                        return;
                    }
                });
            }
        },
        created(){
            let promise = axios.get(apiDomain+'/logineduser',{headers:getHeader()});
            return promise.then((response) => {
                const result = response.data;
                this.getLoginedUser(result);
                //alert(JSON.stringify(result));
              if (response.status === 200 || response.status === 204) {
                console.log(response.status, 'gived status is here...');
              }
               
            }).catch(error => {
                alert(vue.i18n.translate('qaj.reg.idnNotSame'));
                window.location.href = "/#/main";
            });
        }
    }
</script>
<style>
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% высоты экрана */
  }
</style>