<template>

    <b-row>
        <b-col lg="12" class="pb-4">
            
            <h4 class="text-right ">
                <span class="text-info" v-if="car">{{car.model  +':'}}</span> {{car.licencePlate}}
            </h4>
            <hr>
        </b-col>
        <b-col lg="5">
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.class')}}:</b-col>
                <b-col lg="6">
                    <textarea class="form-control" style="min-height:160px" disabled>
                        {{$i18n.locale()=='kz' ? car.vehicleClass.descriptionKz : car.vehicleClass.description}}
                    </textarea>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.group')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" :value="$i18n.locale()=='kz' ? car.vehicleGroup.descriptionKz : car.vehicleGroup.description" disabled>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.model')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" :value="car.model+', '+car.vehicleYear" disabled>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.xin')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" :value="car.owner.xin" disabled>
                </b-col>
            </b-row>
        </b-col>
        <b-col lg="5">
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.account')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" :value="car.accInfo.answer[1].accountNumber" disabled>
                </b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.accountBalance')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" :value="car.accInfo.answer[1].balance" disabled>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.carBalance')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" :value="car.accInfo.answer[0].balance" disabled>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col lg="6" class="text-right pt-2">{{$t('qaj.legal.debt')}}:</b-col>
                <b-col lg="6">
                    <input class="form-control" 
                    :style="{'border-color': car.accInfo.debt>0 ? 'red' : '',}" :value="car.accInfo.debt" disabled>
                </b-col>
            </b-row>
        </b-col>
        <b-col lg="2"></b-col>

        <b-col lg="12">
            <div class="pt-5">
                <b-tabs content-class="mt-3" align="center" @input="tabChange">
                    <b-tab :title="$t('qaj.legal.payment')" active>
                        <div class="w-100 text-center">
                            <b-table
                                ref="paymentRef"
                                striped hover selectable
                                :items="paymentProvider"
                                :fields="fields"
                                :busy.sync="isBusy"
                                :current-page="currentPage"
                                :per-page="perPage"
                                table-variant='light'
                                :filter="filter"
                                :select-mode="selectMode"
                                selected-variant="primary"
                                @row-selected="onRecordSelected"

                                >
                                <template v-slot:cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">Selected</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">Not selected</span>
                                    </template>
                                </template>
                                <template v-slot:table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Жүктелуде...</strong>
                                </div>
                                </template>

                               

                                <template #head(createdDate)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(total)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(description)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template v-slot:cell(paymentType)="row">
                                  <span v-if="row.item.paymentType=='CASH'">
                                        {{$i18n.locale()=='kz' ? 'Қолма-қол ақша' : 'Наличные'}}
                                  </span>
                                  <span v-if="row.item.paymentType=='ACCOUNT'">
                                        {{$i18n.locale()=='kz' ? 'Шоттаң' : 'Со счета'}}
                                  </span>
                                </template>
                                <template v-slot:cell(paymentDetails)="row">
                                    <b-button
                                        :variant="row.item.uuid === selectedPaymentUUID && isModalVisible ? 'success' : 'primary'"
                                        @click="fetchCarPaymentByUUID(row.item.uuid)"
                                    >
                                      {{ $t('qaj.payment.details') }}
                                    </b-button>
                                </template>
                                
                                <template v-slot:cell(description)="row">
                                    {{row.item.descriptionRu}}
                                </template>
                                <template v-slot:cell(sourceCode)="row">
                                    <span v-if="row.item.sourceCode=='PERSONAL_ACCOUNT'">
                                        {{$i18n.locale()=='kz' ? 'Жеке шот' : 'Лицевой счет'}}
                                    </span>
                                    <span v-if="row.item.sourceCode=='VEHICLE_ACCOUNT'">
                                        {{$i18n.locale()=='kz' ? 'МТНБ шотынан' : 'Счета ГРНЗ'}}
                                    </span>
                                    <span v-if="row.item.sourceCode=='HCM'">
                                        {{row.item.sourceCode}}
                                    </span>
                                </template>
                            </b-table>
                          
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                            
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('qaj.legal.history')">
                        <div class="w-100 text-center">
                            <b-table 
                                ref="depositsRef"
                                striped hover selectable
                                :items="depProvider" 
                                :fields="fieldsDep"
                                :busy.sync="isBusyDep"
                                :current-page="currentPageDep"
                                :per-page="perPageDep"
                                table-variant='light'
                                :filter="filter"
                                :select-mode="selectModeDep"
                                selected-variant="primary"
                                @row-selected="onRecordSelectedDep"

                                >
                                <template v-slot:cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">Selected</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">Not selected</span>
                                    </template>
                                </template>
                                <template v-slot:table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Жүктелуде...</strong>
                                </div>
                                </template>

                               

                                <template #head(createdDate)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(total)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(description)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(sourceCode)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(amount)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                
                                <template v-slot:cell(description)="row">
                                    {{row.item.descriptionRu}}
                                </template>

                                <template v-slot:cell(viewInfo)="row">
                                  <b-btn v-if="checkValid(row.item.sourceCode, row.item.createdDate)"
                                         variant="outline-primary"
                                         class="ml-1"
                                         size="sm"
                                         @click="viewInfo(row.item)">
                                    Чек
                                  </b-btn>
                                </template>
                            </b-table>
                          
                            <b-pagination
                                v-model="currentPageDep"
                                :total-rows="totalRowsDep"
                                :per-page="perPage"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('qaj.legal.debt')" >
                        <div class="w-100 text-center">
                            <b-table 
                                ref="loanRef"
                                striped hover selectable
                                :items="loanProvider" 
                                :fields="fieldsLoan"
                                :busy.sync="isBusy"
                                :current-page="currentPageLoan"
                                :per-page="perPageLoan"
                                table-variant='light'
                                :filter="filterLoan"
                                :select-mode="selectModeLoan"
                                selected-variant="primary"
                                @row-selected="onRecordSelected"

                                >
                                <template v-slot:cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">Selected</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">Not selected</span>
                                    </template>
                                </template>
                                <template v-slot:table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Жүктелуде...</strong>
                                </div>
                                </template>

                               

                                
                                <template #head(amount)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(passageDate)="data">
                                    <span>{{ $t(data.label) }}</span>
                                </template>
                                <template #head(roadSectorName)="data">
                                    <span>{{ $t(data.roadSectorName) }}</span>
                                </template>
                                
                            </b-table>
                            <PaymentDetailsModal
                                :isVisible.sync="isModalVisible"
                                :paymentDetails="selectedPaymentDetails"
                                @hide="isModalVisible = false"
                            />
                          
                            <b-pagination
                                v-model="currentPageLoan"
                                :total-rows="totalRowsLoan"
                                :per-page="perPage"
                            
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </b-col>
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import PaymentDetailsModal from "@/components/law/PaymentDetailsModal.vue";
    export default {
        name:"CarInfo",
        components: {
          PaymentDetailsModal
        },
        data(){
            return {
                selectedPaymentUUID: null,
                selectedPaymentDetails: null,
                isModalVisible: false,
                car:{},
                licencePlate:"",
                sourceCode: "",
                toastCount: 0,

                //begining of the payments 
                fields: [
               
           
                    {
                        key: 'createdDate',
                        label: 'qaj.payment.date',
                        thStyle: { width: "20%" } ,
                        
                    },
                    {
                        key: 'total',
                        label: 'qaj.payment.total',
                    
                    },
                    {
                        key: 'description',
                        label: 'qaj.payment.description',
                    },
                    {
                        key: 'sourceCode',
                        label: 'Вид оплаты',
                    },
                    {
                        key: 'paymentType',
                        label: 'Тип оплаты',
                    },
                    {
                      key: 'paymentDetails',
                      label: '',
                    },
                ],
                isBusy:false,
                totalRows:0,
                currentPage: 1,
                perPage: 20,
                tableVariant:'light',
                filter:"",
                selectMode:'single',
                selectedRows:[],
                accountNumber:"",
                //end of the payments 

                //begining of the deposits 
                fieldsDep: [
               
           
                    {
                        key: 'createdDate',
                        label: 'qaj.payment.date',
                        thStyle: { width: "20%" } ,
                        
                    },
                    {
                        key: 'amount',
                        label: 'qaj.payment.amount',
                    
                    },
                   
                    {
                        key: 'sourceCode',
                        label: 'qaj.payment.sourceCode',
                    },
                    {
                      key: 'viewInfo',
                      label: '',
                    },
                ],
                isBusyDep:false,
                totalRowsDep:0,
                currentPageDep: 1,
                perPageDep: 20,
                tableVariantDep:'light',
                filterDep:"",
                selectModeDep:'single',
                selectedRowsDep:[],
                accountNumberDep:"",
                //end of the deposits 


                //begining of the loan 
                fieldsLoan: [
                    {
                        key: 'passageDate',
                        label: 'qaj.payment.date',
                        thStyle: { width: "20%" } ,
                        
                    },
                    {
                        key: 'amount',
                        label: 'qaj.payment.amount',
                    
                    },
                   
                    {
                        key: 'roadSectorName',
                        label: 'qaj.payment.roadSectorName',
                    },
                ],
                isBusyLoan:false,
                totalRowsLoan:0,
                currentPageLoan: 1,
                perPageLoan: 20,
                tableVariantLoan:'light',
                filterLoan:"",
                selectModeLoan:'single',
                selectedRowsLoan:[],
                accountNumberLoan:""
                //end of the loan 
                
            }
        },
        methods:{
          checkValid(sourceCode, createdDate) {
            const validSources = [
              "SENIM",
              "BCC",
              "QIWI",
              "HALYKBANK",
              "TENGE",
              "KAZPOST",
              "E100",
              "KAZTOLL",
              "AQQAUNT",
              "KASPIBANK",
              "SMARTPAY",
              "PAYDALA",
              "ASTANAPLAT",
              "WOOPPAY",
              "CYBERPLAT",
              "KASSA24_MOBILE",
              "WOOPPAY_MOBILE",
              "WOOPPAY_ORIGINAL",
              "QIWI_MOBILE",
              "MAXIPAY",
              "IAC"
            ];
            const [datePart] = createdDate.split(' ');
            const [day, month, year] = datePart.split('-');
            const inputDate = new Date(year, month - 1, day);
            const targetDate = new Date(2021, 10, 3);
            return validSources.includes(sourceCode) && inputDate >= targetDate;
          },
          //Чеки доступны с 3 ноября 2021 года
          viewInfo(ctx, append = false) {
            this.toastCount++
            //alert("Чеки доступны с 3 ноября 2021 года")
            ctx.licencePlate = this.licencePlate
            console.log("open new link where you see ticket", ctx)
            this.isBusy = false

            let promise = axios.post(apiDomain+'/law/cartickets', ctx, {headers:getHeader()})
                .then(response => {
                  const result = response.data;
                  if(result.success) {
                    const link = result.answer;

                    if(link) {
                      window.open(link, '_blank');
                    } else {
                      console.error('not found link');
                    }
                  }
                  else {
                    this.$bvToast.toast(`Чек не найден`, {
                      title: 'Внимание',
                      autoHideDelay: 5000,
                      appendToast: append
                    })
                  }
                  this.isBusy = false
                })
                .catch(error => {
                  console.error('Error request:', error);
                  this.isBusy = false;

                })

            return promise.then((response) => {
              const result = response.data;
              this.isBusy = false
              this.totalRows=result.answer.totalElements;

              return(result.answer.content)
            }).catch(error => {
              this.isBusy = false
              return []
            })
          },

          fetchCarPaymentByUUID(uuid) {
              this.selectedPaymentUUID = uuid;
              this.isBusy = true;
              axios.post(`${apiDomain}/law/carpaymentdetails`, { uuid }, { headers: getHeader() })
                  .then((response) => {
                    const result = response.data;
                    if (response.status == 200) {
                      this.selectedPaymentDetails = result;
                      this.isModalVisible = true;
                    } else {
                      console.error('API response indicates failure:', result);
                    }
                    this.isBusy = false;
                  })
                  .catch((error) => {
                    this.isBusy = false;
                    this.$bvToast.toast(error.message, {
                      title: 'Ошибка',
                      autoHideDelay: 5000,
                      variant: 'danger'
                    });
                  });
            },

            paymentProvider(ctx){
                ctx.licencePlate=this.licencePlate;
                console.log(ctx);
                //alert(ctx.accountNumber);
                this.isBusy = false
                let promise = axios.post(apiDomain+'/law/carpayments',ctx,{headers:getHeader()});
                    return promise.then((response) => {

                        const result = response.data;
                        this.isBusy = false
                        this.totalRows=result.answer.totalElements;

                        return(result.answer.content)


                    }).catch(error => {
                        this.isBusy = false
                        return []
                    })
            },
            tabChange(index){
                if(index==0){
                    this.$refs.paymentRef.refresh();
                }
                if(index==1){
                    this.$refs.depositsRef.refresh();
                }
                if(index==2){
                    this.$refs.loanRef.refresh();
                }
            },
            depProvider(ctx){
                ctx.licencePlate=this.licencePlate;
                console.log(ctx);
                //alert(ctx.accountNumber);
                this.isBusyDep = false
                let promise = axios.post(apiDomain+'/law/cardeposits',ctx,{headers:getHeader()});
                    return promise.then((response) => {
                        //console.log("so important ",response.data);
                        const result = response.data;
                        this.isBusyDep = false
                        this.totalRowsDep=result.answer.totalElements;
                        
                        return(result.answer.content)


                    }).catch(error => {
                        this.isBusy = false
                        return []
                    })
            },
            loanProvider(ctx){ 
                ctx.licencePlate=this.licencePlate;
                console.log(ctx);
                this.isBusyLoan = false
                let promise = axios.post(apiDomain+'/law/carloans',ctx,{headers:getHeader()});
                    return promise.then((response) => {
                        const result = response.data;
                        this.isBusyLoan = false
                        this.totalRowsLoan=result.totalElements;
                        
                        return(result.content)


                    }).catch(error => {
                        this.isBusy = false
                        return []
                    })
            },
            onRecordSelected(items){
                this.selectedRows=items;
            },
            onRecordSelectedDep(items){
                this.selectedRowsDep=items;
            },
            CarInfo(){
                if(this.licencePlate.length>0){
                    axios.post(apiDomain+'/law/carinfo',{"licencePlate":this.licencePlate},{headers:getHeader()})
                    .then((res)=>{
                        this.car=res.data.answer;
                        //alert(JSON.stringify(this.car));
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        })
                    }) 
                }
            }
        },
        created(){
            this.licencePlate = this.$route.params.plate;
            this.CarInfo();  
        },
        watch: {
            $route(to, from) {
                //console.log(to);
                this.licencePlate = this.$route.params.plate;
                this.CarInfo();
            }
        } 
    }
</script>